import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
const ExecutiveCommitee = () => {
  return (
    <div>
      <Layout>
        <SEO title="Executive Committee" />
        <PageStructure
          title="Executive Committee"
          desc="TESTIG"
          mode={ABOUTUS}
          content={`
          
          The Indian Revenue Service Association was formed in 1961 with the Indian Revenue Service Officers recruited directly through Civil Service Examination as members. Though, most of the Members of the Association are working in the Income Tax Department in the Ministry of Finance, many are working on deputation and loan basis in Indian Embassies, OECD, World Bank, IMF, ADB, African countries, PSEs, other Ministries of the Government of India, State Governments etc.
          <br/><br/>
          The total strength of IRSA is 2792 members. The latest constitution of the Association was adopted by the AGM in 2012. The All India Body of the Association has following posts for which the office bearers are elected by all members annually
          <br/>
          <ol>
            <li>President</li>
            <li>Vice President</li>
            <li>General Secretary</li>
            <li>Joint General Secretaries (Three posts)</li>
            <li>Treasurer</li>
            <li>Executive Committee Members (Nine posts)</li>
            <li>One Executive Committee Member elected by Nagpur Unit from the officers posted in the National Academy of Direct Taxes</li>
            <li>The Chairman of each of the eight major Units at Delhi, Mumbai, Kolkata, Chennai, Bengaluru, Hyderabad, Pune and Ahmedabad are ex-officio Vice Presidents. The Presidents of the other local units are permanent invitees to the Central Executive Committee</li>
          </ol>
          <br/>
          The All India Body of the Association can also co-opt more Executive Committee Members through a simple resolution. 
          `}
        />
      </Layout>
    </div>
  )
}

export default ExecutiveCommitee
